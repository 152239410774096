import React from "react";
import man3 from "../../assets/images/man3.jpg";
import man4 from "../../assets/images/man4.jpg";
import circles from "../../assets/images/circles.png";
import Slider from "react-slick";
import { motion, useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

const ExpertSection = () => {
  const { t, i18n } = useTranslation();

  const controls5 = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.5 });

  React.useEffect(() => {
    if (inView) {
      controls5.start("visible");
    }
  }, [controls5, inView]);

  const textAlignment = i18n.language === "ar" ? "text-end" : "text-start";

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div className="custom-dot">
        <div className="dot"></div>
      </div>
    ),
  };
  return (
    <section className="bg-[#FD7B03] text-white pt-20 pb-20 md:pb-32">
      <div className="hidden xl:flex relative bg-[#1a1a1a] h-0 -bottom-[320px] md:-bottom-[270px] xl:-bottom-52 2xl:-bottom-48">
        <svg
          className="absolute bottom-0 w-full h-screen"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="#FFAA4C"
            fillOpacity="1"
            d="M0,224L60,213.3C120,203,240,181,360,176C480,171,600,181,720,192C840,203,960,213,1080,208C1200,203,1320,181,1380,176L1440,171L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          ></path>
        </svg>
        <svg
          className="absolute bottom-0 w-full h-screen"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="#FD7B03"
            fillOpacity="1"
            d="M0,288L60,272C120,256,240,224,360,224C480,224,600,256,720,266.7C840,277,960,267,1080,245.3C1200,224,1320,192,1380,176L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          ></path>
        </svg>
      </div>

      <div className="flex relative z-20">
        <div className="bg-[#FFA451] rounded-r-xl pt-5 md:pl-32 pl-6 pr-2">
          <h2 className="text-2xl md:text-4xl font-bold mb-4">
            {t("meetOurExpert")}
          </h2>
          <h2
            className={`text-4xl md:text-7xl font-bold mb-4 ${textAlignment}`}
          >
            {t("drMichel")}
          </h2>
        </div>
        <div>
          <h2
            className={`text-4xl md:text-7xl font-bold absolute bottom-4 ml-2 ${textAlignment}`}
          >
            {t("adib")}
          </h2>
        </div>
      </div>

      <div className="md:w-[85%] md:max-w-[1300px] mx-auto flex flex-col-reverse justify-between gap-x-10 md:flex-row md:items-center mt-20 pt-10 md:top-0">
        <div className={`${textAlignment} max-w-1/2`}>
          <div className="ml-3 w-full">
            <motion.div
              className={`${
                i18n.language === "en"
                  ? "md:mr-auto w-full md:w-[75%]"
                  : "md:ml-auto pr-8 w-full md:w-[85%]"
              } mt-10 md:mt-0 w-auto`}
              ref={ref}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              initial="hidden"
              variants={{
                visible: { opacity: 1, scale: 1, y: 0 },
                hidden: { opacity: 0, scale: 0.95, y: 50 },
              }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            >
              <p className="text-2xl font-bold">{t("myPhilosophy")}</p>
              <p
                dir={`${i18n.language === "en" ? "ltr" : "rtl"}`}
                className="text-3xl text-start font-bold mt-6 leading-snug"
              >
                {t("philosophyText1")}
              </p>
              <p className="text-2xl mt-8">{t("philosophyText4")}</p>
              <p className="text-2xl">{t("philosophyText5")}</p>
              <p className="text-2xl">{t("philosophyText6")}</p>
              <p className="text-2xl">{t("philosophyText7")}</p>
              <p className="text-2xl">{t("philosophyText8")}</p>
              <p className="text-2xl font-bold">{t("philosophyText9")}</p>
              <p className="text-xl mt-3 opacity-60">{t("founderText")}</p>
            </motion.div>
          </div>
        </div>

        <div className="mx-auto relative z-10 px-5 md:px-10 max-w-[400px] md:min-w-[32%] md:max-w-[520px]">
          <Slider {...settings} className="rounded-lg">
            <div className="rounded-2xl flex justify-center items-center">
              <img
                src={man3}
                className="rounded-2xl object-cover w-full h-full"
                alt="Slide 2"
              />
            </div>
            <div className="rounded-2xl flex justify-center items-center">
              <img
                src={man4}
                className="rounded-2xl object-contain w-full h-full"
                alt="Slide 3"
              />
            </div>
          </Slider>
          <img
            src={circles}
            className="w-[900px] -z-10 absolute -top-20 right-0 md:right-2"
            alt="Circles Background"
          />
        </div>
      </div>
      <div id="svg" className="hidden xl:flex relative bg-[#1a1a1a]">
        {/* <svg
          className="absolute top-full w-full"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="#FFAA4C"
            fillOpacity="1"
            d="M0,224L60,213.3C120,203,240,181,360,176C480,171,600,181,720,192C840,203,960,213,1080,208C1200,203,1320,181,1380,176L1440,171L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          ></path>
        </svg> */}
        <svg
          className="absolute xl:-top-36 w-full z-10"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="#151515"
            fillOpacity="1"
            d="M0,288L60,272C120,256,240,224,360,224C480,224,600,256,720,266.7C840,277,960,267,1080,245.3C1200,224,1320,192,1380,176L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          ></path>
        </svg>
      </div>
    </section>
  );
};

export default ExpertSection;
