import React, { useState, useEffect } from "react";
import api from "../../../api/api";
import { FaPlusCircle } from "react-icons/fa";
import EditForm from "./EditForm";
import AddForm from "./AddForm";
import Loader from "../../../components/Loader/Loader";
import Table from "../../../components/Table/Table";

const Meals = () => {
  const [meals, setMeals] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [currentMeal, setCurrentMeal] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(false);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Show 20 meals per page

  const token = localStorage.getItem("authenticatedToken");

  useEffect(() => {
    fetchMeals();
  }, []);

  useEffect(() => {
    if (change) {
      fetchMeals();
      setChange(false);
    }
  }, [change]);

  const fetchMeals = async () => {
    setLoading(true);

    try {
      const response = await api.get("/admin/meals?paginate=1000", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMeals(response.data.data);
    } catch (error) {
      // alert("Error fetching meals!");
      console.log("Error fetching meals!");
    } finally {
      setLoading(false);
    }
  };

  const addMeal = async (meal) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      await api.post("/admin/meals", meal, config);

      // setMeals((prevMeals) => [...prevMeals, response.data]);
      setShowAddForm(false);
      setChange(true);
    } catch (error) {
      // console.error("Error adding meal:", error.response || error.message);
      alert(`Error adding meal`);
    }
  };

  const updateMeal = async (updatedMeal) => {
    if (!updatedMeal.id) {
      alert("Updated meal does not have an ID");
      return;
    }

    try {
      setEditing(true);

      await api.post(`/admin/meals/${updatedMeal.id}`, updatedMeal, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      setShowEditForm(false);
      setCurrentMeal(null);
      setChange(true);
    } catch (error) {
      // console.error("Error updating meal:", error);
      alert(`Error updating meal`);
    } finally {
      setEditing(false);
    }
  };

  const deleteMeal = async (id) => {
    try {
      await api.delete(`/admin/meals/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // setMeals((prevMeals) => prevMeals.filter((meal) => meal.id !== id));
      setChange(true);
    } catch (error) {
      // console.error("Error deleting meal:", error);
      alert(`Error deleting meal`);
    }
  };

  const handleEditClick = (meal) => {
    setCurrentMeal(meal);
    setShowEditForm(true);
  };

  const handleEditFormChange = (name, value) => {
    setCurrentMeal((prevMeal) => ({ ...prevMeal, [name]: value }));
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
    setCurrentPage(1); // Reset to first page on sort
  };

  const getFilteredAndSortedMeals = () => {
    const filteredMeals = meals.filter((meal) => {
      // Check if meal.title is defined before calling toLowerCase()
      return (
        meal.title &&
        meal.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });

    const sortedMeals = filteredMeals.sort((a, b) => {
      if (sortOrder === "asc")
        return (a.title || "").localeCompare(b.title || "");
      if (sortOrder === "desc")
        return (b.title || "").localeCompare(a.title || "");
      if (sortOrder === "oldest")
        return new Date(a.created_at) - new Date(b.created_at);
      if (sortOrder === "newest")
        return new Date(b.created_at) - new Date(a.created_at);
      return 0;
    });

    return sortedMeals;
  };

  const filteredAndSortedMeals = getFilteredAndSortedMeals();
  const totalPages = Math.ceil(filteredAndSortedMeals.length / itemsPerPage);
  const currentMeals = filteredAndSortedMeals.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const headers = [
    "Image",
    "Title",
    "Meal Type",
    "Calories",
    "Carbs",
    "Protein",
    "Fats",
    "Tags",
    "Ingredients",
  ];

  const renderRow = (meal) => (
    <>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 min-w-36">
        {meal.photos && meal.photos.length > 0 ? (
          <img
            src={meal.photos[0].url}
            alt="meal"
            className="rounded-full w-24 h-24 object-cover -ml-2"
          />
        ) : (
          <span className="text-gray-500">No image</span>
        )}
      </td>

      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 max-w-36 overflow-hidden">
        {meal.title || ""}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {meal.meal_type || ""}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {meal.nutrition_data?.calories || ""}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {meal.nutrition_data?.carbs || ""}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {meal.nutrition_data?.protein || ""}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {meal.nutrition_data?.fats || ""}
      </td>
      <td className="px-4 py-2">
        {meal.tags?.map((tag, index) => (
          <div key={index}>
            <span className="font-semibold">{tag.name || ""}</span>
          </div>
        ))}
      </td>
      <td className="border border-gray-300 px-4 py-2">
        {meal.ingredients?.map((ingredient, index) => (
          <div key={index}>
            <span className="font-bold">{ingredient.name.en || ""}:</span>
            <span className="ml-10">{ingredient.quantity || ""}</span>
            <hr />
          </div>
        ))}
      </td>
    </>
  );

  return (
    <div className="px-4 mt-8 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold text-gray-900">
            Meal Dashboard
          </h1>
        </div>
      </div>
      {showAddForm && (
        <AddForm onSave={addMeal} onCancel={() => setShowAddForm(false)} />
      )}
      {showEditForm && (
        <EditForm
          formData={currentMeal}
          onSave={updateMeal}
          onCancel={() => setShowEditForm(false)}
          onChange={handleEditFormChange}
          editing={editing}
        />
      )}
      <div className="mt-8 flex flex-col">
        <div className="flex sm:flex-row flex-col space-x-4">
          <div className="sm:w-1/3 mb-4">
            <input
              type="text"
              id="search"
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Search by Title"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <div className="sm:w-1/3 mb-4">
            <label htmlFor="sort" className="sr-only">
              Sort
            </label>
            <select
              id="sort"
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={sortOrder}
              onChange={handleSortChange}
            >
              <option value="asc">Name (A-Z)</option>
              <option value="desc">Name (Z-A)</option>
              <option value="oldest">Oldest</option>
              <option value="newest">Newest</option>
            </select>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none"
              onClick={() => setShowAddForm(true)}
            >
              <FaPlusCircle className="mr-2" />
              Add Meal
            </button>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <Table
            headers={headers}
            data={currentMeals}
            onEdit={handleEditClick}
            onDelete={deleteMeal}
            renderRow={renderRow}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default Meals;
