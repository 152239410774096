import api from "../../../../api/api";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import Loader from "../../../../components/Loader/Loader";
import { IoCheckbox } from "react-icons/io5";
import { ImCheckboxUnchecked } from "react-icons/im";
import WorkoutDetails from "./WorkoutDetails";
import AddRoutines from "./AddRoutines";
import { FaPlusCircle, FaToggleOff, FaToggleOn } from "react-icons/fa";
import AddMeals from "./AddMeals";
import nullImage from "../../../../assets/images/nullimage.png";
import UserDetails from "./UserDetails";
import Table from "../../../../components/Table/Table";
import EditMeal from "./EditMeal";

const UserProfile = () => {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [userMeal, setUserMeal] = useState([]);
  const [userWorkout, setUserWorkout] = useState([]);
  const [loading, setLoading] = useState(true); // Added loading state
  const [showWorkoutsSchedule, setShowWorkoutsSchedule] = useState(false);
  const [showMealsSchedule, setShowMealsSchedule] = useState(false);
  const [plans, setPlans] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showEditMeals, setShowEditMeals] = useState(false);
  const [currentMeal, setCurrentMeal] = useState(null);

  const token = localStorage.getItem("authenticatedToken");
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [fullscreenImage2, setFullscreenImage2] = useState(null);
  const [shouldFetch, setShouldFetch] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchUser(),
        fetchUserMeal(),
        fetchUserWorkout(),
        fetchPlans(),
      ]);
      setLoading(false); // Set loading to false after data is fetched
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (shouldFetch) {
      fetchUser();
      fetchUserMeal();
      fetchUserWorkout();
      setShouldFetch(false); // Reset the flag
    }
  }, [shouldFetch]);

  const fetchUser = async () => {
    try {
      const response = await api.get(`/admin/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log("res", response.data.data);
      setUser(response.data.data);
    } catch (error) {
      console.log("Error fetching user");
      // alert("Error fetching user");
    }
  };

  const fetchUserMeal = async () => {
    // console.log("runs");
    try {
      const response = await api.get(`/admin/users/${id}/meals?paginate=1000`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log("user mealls runs");
      // console.log(response.data.data);
      setUserMeal(response.data.data);
    } catch (error) {
      console.log("Error fetching user meals");
      // alert("Error fetching user meals");
    }
  };

  const fetchUserWorkout = async () => {
    try {
      const response = await api.get(
        `/admin/users/${id}/workouts?paginate=1000`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("get res --->", response.data.data);
      setUserWorkout(response.data.data);
    } catch (error) {
      console.log("Error fetching user meals");
      // alert("Error fetching user meals");
    }
  };

  const fetchPlans = async () => {
    try {
      const response = await api.get("/admin/plans?paginate=1000", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPlans(response.data.data);
    } catch (error) {
      console.log("Error fetching plans");
      // alert("Error fetching plans");
    }
  };

  const toggleUserActivation = async () => {
    try {
      const updatedActivation = {
        user_id: id,
      };

      if (user.has_active_subscription) {
        // Deactivate the user
        const response = await api.post(
          `/admin/plans/deactivate/user`,
          updatedActivation,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setShouldFetch(true);
      } else {
        setShowModal(true);
      }
    } catch (error) {
      alert("Error toggling user activation");
    }
  };

  const handlePlanSelect = (planId) => {
    setSelectedPlan(planId);
  };

  const handleActivationSubmit = async () => {
    if (!selectedPlan) {
      alert("Please select a plan.");
      return;
    }

    try {
      const updatedActivation = {
        user_id: id,
        plan_id: selectedPlan,
      };
      await api.post(`/admin/plans/subscribe/user`, updatedActivation, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setShowModal(false);
      setShouldFetch(true);
    } catch (error) {
      alert("Error toggling user activation");
    }
  };

  const handleImageClick = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const handleImageClick2 = (imageUrl) => {
    setFullscreenImage2(imageUrl);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
    setFullscreenImage2(null);
  };

  // Format date to a more readable format
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const deleteMeal = async (pivotId) => {
    // console.log("pivotId:", pivotId);
    try {
      await api.delete(`/admin/user-meals/${pivotId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setShouldFetch(true);
    } catch (error) {
      alert("Error deleting exercise");
    }
  };

  const handleEditClick = (meal) => {
    setCurrentMeal(meal);
    setShowEditMeals(true);
  };

  const handleEditFormChange = (name, value) => {
    setCurrentMeal({ ...currentMeal, [name]: value });
  };

  const headersm = ["Name", "Meal Type", "Meal Date", "change meal", "eaten"];

  const renderRowm = (meal) => (
    <>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {meal.title}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {meal.meal_type}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {meal.pivot.meal_date}
      </td>
      <td className="px-14 py-4 whitespace-nowrap text-sm text-gray-500">
        {meal.pivot?.is_modified === 0 ? (
          <ImCheckboxUnchecked />
        ) : (
          <>
            <IoCheckbox />
          </>
        )}
      </td>
      <td className="px-10 py-4 whitespace-nowrap text-sm text-gray-500">
        {meal.pivot.consumed_at == null ? (
          <ImCheckboxUnchecked />
        ) : (
          <>
            <IoCheckbox />
            {formatDate(meal.pivot.consumed_at)}
          </>
        )}
      </td>
    </>
  );

  const headersw = ["Name", "Finish", "Exercises"];

  const renderRoww = (workout) => (
    <>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {workout.title}
      </td>
      <td className="pl-6 items-center text-sm font-medium text-gray-900">
        {workout.pivot.is_finished == 0 ? (
          <ImCheckboxUnchecked />
        ) : (
          <>
            <IoCheckbox />
          </>
        )}
      </td>

      <td>
        <WorkoutDetails workout={workout} />
      </td>
    </>
  );

  if (loading) {
    return <Loader />; // Show loader while data is being fetched
  }
  // console.log(user);
  // console.log(user.main_images);
  return (
    <div className="p-8 bg-gray-100 min-h-screen overflow-hidden">
      <div className="flex items-center mb-8">
        <div>
          <img
            className="w-24 mr-4"
            alt=""
            src={user?.profile_image[0]?.url || nullImage}
          />
        </div>
        <div>
          <h2 className="text-3xl font-bold ">{user?.name || "N/A"}</h2>
          <p className="text-gray-600 ">{user?.email || "N/A"}</p>
        </div>
      </div>
      <div className="flex md:space-x-8 md:space-y-0 space-y-4 pb-4 flex-col md:flex-row justify-around">
        <button
          type="button"
          className={`h-12 inline-flex items-center px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
            user.has_active_subscription ? "bg-red-600" : "bg-green-600"
          } hover:bg-${
            user.has_active_subscription ? "red" : "green"
          }-700 focus:outline-none`}
          onClick={toggleUserActivation}
        >
          {user.has_active_subscription ? (
            <>
              <FaToggleOff className="mr-2" />
              Deactivate
            </>
          ) : (
            <>
              <FaToggleOn className="mr-2" />
              Activate
            </>
          )}
        </button>
        <button
          type="button"
          className="h-12 inline-flex items-center px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none"
          onClick={() => setShowWorkoutsSchedule(true)}
        >
          <FaPlusCircle className="mr-2" />
          Routines Schedule
        </button>
        <button
          type="button"
          className="h-12 inline-flex items-center px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none"
          onClick={() => setShowMealsSchedule(true)}
        >
          <FaPlusCircle className="mr-2" />
          Meals Schedule
        </button>
      </div>
      {showWorkoutsSchedule && (
        <AddRoutines
          setShouldFetch={setShouldFetch}
          CloseWorkoutsSchedule={() => setShowWorkoutsSchedule(false)}
        />
      )}

      {showMealsSchedule && (
        <AddMeals
          setShouldFetch={setShouldFetch}
          CloseMealsSchedule={() => setShowMealsSchedule(false)}
        />
      )}
      {showModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Select a Plan
                </h3>
                <div className="mt-2">
                  {plans && plans.length > 0 ? (
                    plans.map((plan, index) => (
                      <button
                        key={`plans${index}`}
                        onClick={() => handlePlanSelect(plan.id)}
                        className={`block w-full text-left p-4 my-2 border ${
                          selectedPlan === plan.id
                            ? "border-blue-500"
                            : "border-gray-300"
                        } rounded-lg cursor-pointer`}
                      >
                        {plan.name} - {plan.duration} Month(s)
                      </button>
                    ))
                  ) : (
                    <p>No plans available</p>
                  )}
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none sm:text-sm"
                  onClick={handleActivationSubmit}
                >
                  Activate Plan
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Personal Details:</h3>
        <UserDetails user={user} formatDate={formatDate} />
        <div className="p-8 bg-gray-100">
          <h1 className="text-3xl font-semibold">User Images</h1>
          {/* Image Gallery */}
          <div className="image-gallery">
            {user.main_images && user.main_images.length > 0
              ? user?.main_images?.map((image, index) => (
                  <img
                    key={`user${index}`}
                    className="w-24 inline m-5 cursor-pointer"
                    src={image.url}
                    alt={image.name}
                    onClick={() => handleImageClick(image.url)}
                  />
                ))
              : "No Images"}
          </div>

          {/* Fullscreen Image Modal */}
          {fullscreenImage && (
            <div
              className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
              onClick={closeFullscreen}
            >
              <img
                className="max-w-full max-h-full"
                src={fullscreenImage}
                alt="Fullscreen View"
              />
            </div>
          )}
        </div>

        <div className="p-8 bg-gray-100">
          <h1 className="text-3xl font-semibold">Payments Images</h1>
          {/* Image Gallery */}
          <div className="image-gallery md:flex md:space-x-5">
            {user.payment_receipt && user.payment_receipt.length > 0
              ? user.payment_receipt.map((image, index) => (
                  <div key={uuidv4()}>
                    <img
                      className="w-24 m-5 cursor-pointer"
                      src={image.url}
                      alt={image.name}
                      onClick={() => handleImageClick2(image.url)}
                    />
                    <p>
                      {image?.created_at ? formatDate(image.created_at) : "N/A"}
                    </p>
                  </div>
                ))
              : "No Images"}
          </div>

          {/* Fullscreen Image Modal */}
          {fullscreenImage2 && (
            <div
              className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
              onClick={closeFullscreen}
            >
              <img
                className="max-w-full max-h-full"
                src={fullscreenImage2}
                alt="Fullscreen View"
              />
            </div>
          )}
        </div>
      </section>
      {showEditMeals && (
        <EditMeal
          formData={currentMeal}
          setShouldFetch={setShouldFetch}
          onChange={handleEditFormChange}
          CloseMealsSchedule={() => setShowEditMeals(false)}
        />
      )}
      <div className="mb-10 md:mb-0">
        <p className="font-semibold text-3xl mb-4">Meals Schedule</p>
        <div className="overflow-x-auto">
          <Table
            headers={headersm}
            data={userMeal}
            renderRow={renderRowm}
            onEdit={handleEditClick}
            onDelete={deleteMeal}
            pivotId={true}
          />
        </div>
      </div>

      <div>
        <p className="font-semibold text-3xl mb-4">Workouts Schedule</p>
        <div className="overflow-x-auto">
          <Table headers={headersw} data={userWorkout} renderRow={renderRoww} />
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
