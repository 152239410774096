import React, { useEffect, useState } from "react";
import phoneImage from "../../assets/images/phoneImage.png";
import phoneImgAr from "../../assets/images/phoneImageAR.png";
import Orangepal2 from "../../assets/images/Orangepal2.png";
import AppStore from "../../assets/images/App Store.svg";
import GooglePlay from "../../assets/images/Google Play.png";
import DownloadApk from "../../assets/images/Download-APK.svg";
import { useTranslation } from "react-i18next";

const APKLINK = process.env.REACT_APP_API_URL_APK;
const APPLELINK = process.env.REACT_APP_API_URL_APPLE;

const HeroSection = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("ar");
  const { t, i18n } = useTranslation();

  console.log(APKLINK);
  console.log(APPLELINK);

  useEffect(() => {
    // Initialize language from localStorage if available
    const storedLng = localStorage.getItem("lng");
    if (storedLng) {
      setSelectedLanguage(storedLng);
      i18n.changeLanguage(storedLng);
    } else {
      setSelectedLanguage(selectedLanguage);
      localStorage.setItem("lng", selectedLanguage);
    }
  }, [i18n, i18n.language]);

  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  const handleLanguageChange = () => {
    const newLanguage = selectedLanguage === "en" ? "ar" : "en";
    setSelectedLanguage(newLanguage);
    handleChangeLng(newLanguage); // Trigger the language change in your app
  };
  return (
    <section className="relative overflow-hidden bg-gradient-to-br from-[#FF6400] to-[#FE8E22] text-white">
      <div className="flex justify-around xl:gap-x-96 pt-6">
        <div>
          <img
            src={Orangepal2}
            alt="Orangefit Logo"
            className="w-40 mb-8 md:w-48"
          />
        </div>
        <button
          className="cursor-pointer font-semibold -mt-12 text-2xl"
          onClick={handleLanguageChange}
        >
          {selectedLanguage === "en" ? "عربي" : "English"}
        </button>
      </div>

      <div className="md:w-[85%] md:max-w-[1300px] mx-auto px-4 flex flex-col items-center md:flex-row md:justify-between">
        <div
          className={`text-center ${
            selectedLanguage === "en" ? "md:text-start" : "md:text-end"
          } z-10`}
        >
          <h1 className="text-2xl md:text-6xl font-bold mb-2">{t("Reach")}</h1>
          <h2 className="text-2xl md:text-6xl mb-6 md:mb-12">{t("with")}</h2>
          <p
            dir={`${selectedLanguage === "en" ? "ltr" : "rtl"}`}
            className={`${
              selectedLanguage === "en" ? "md:w-[75%]" : ""
            } md:text-start mb-6 md:mb-12 text-lg opacity-85 font-normal md:font-semibold md:text-2xl`}
          >
            {t("Orangefit")}
          </p>
          <div
            dir={`${selectedLanguage === "en" ? "ltr" : "rtl"}`}
            className="flex flex-col md:flex-row justify-center items-center md:justify-start gap-y-4 md:gap-y-0 md:gap-x-12"
          >
            {/* <a
              href="http://"
              target="_blank"
              rel="noopener noreferrer"
              className="transform transition-transform duration-200 hover:scale-105 hover:shadow-lg rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <img src={GooglePlay} className="w-60" alt="Google Play" />
            </a> */}
            <a
              href={APKLINK}
              target="_blank"
              rel="noopener noreferrer"
              className="transform transition-transform duration-200 hover:scale-105 hover:shadow-lg rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <img src={DownloadApk} className="w-60" alt="Download APK" />
            </a>
            <a
              href={APPLELINK}
              target="_blank"
              rel="noopener noreferrer"
              className="transform transition-transform duration-200 hover:scale-105 hover:shadow-lg rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <img src={AppStore} className="w-60" alt="App Store" />
            </a>
          </div>
        </div>
        <div className="ml-6 z-10 mt-12 md:mt-0 -mb-96 md:-mb-0">
          <img
            src={phoneImgAr}
            alt="Mobile App"
            className={`${selectedLanguage === "en" && "hidden"} w-80`}
          />
          <img
            src={phoneImage}
            alt="Mobile App"
            className={`${selectedLanguage === "ar" && "hidden"} w-80`}
          />
        </div>
        <div className="absolute z-0 bottom-[380px] md:-bottom-16 left-0 w-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className="w-full h-full"
          >
            <path
              fill="#151515"
              fillOpacity="1"
              d="M0,192L60,192C120,192,240,192,360,186.7C480,181,600,171,720,154.7C840,139,960,117,1080,112C1200,107,1320,117,1380,122.7L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            ></path>
          </svg>
        </div>
      </div>
      <div className="bg-[#151515] h-96 z-50 md:hidden"></div>
    </section>
  );
};

export default HeroSection;
