import React from "react";

const UserDetails = ({ user, formatDate }) => {
  const userDetails = [
    { label: "Sex", value: user?.sex },
    { label: "Phone", value: user?.phone },
    { label: "Instagram Account", value: user?.instagram_account },
    { label: "Birth Date", value: user?.birth_date },
    { label: "Country", value: user?.country },
    { label: "City", value: user?.city },
    { label: "Location", value: user?.location },
    { label: "Goal", value: user?.goal },
    { label: "Weight", value: user?.weight },
    { label: "Height", value: user?.height },
    { label: "Required Calories", value: user?.required_calories },
    { label: "Workout Days", value: user?.workout_days_count },
    { label: "Previous Diet Status", value: user?.previous_diet_status },
    { label: "Has Allergies", value: user?.has_allergies },
    { label: "Allergies Details", value: user?.allergies_details },
    { label: "Smoking Status", value: user?.smoking_status },
    { label: "Alcohol Status", value: user?.alcohol_status },
    { label: "Sports Practiced", value: user?.sports_practiced },
    { label: "Uses Protein Supplement", value: user?.uses_protein_supplement },
    { label: "Unwanted Carbohydrate", value: user?.unwanted_carbohydrate },
    { label: "Unwanted Protein", value: user?.unwanted_protein },
    { label: "Unwanted Fats", value: user?.unwanted_fats },
    { label: "Registration Complete", value: user?.registration_complete },
    { label: "Onboarding Current Step", value: user?.onboarding_current_step },
    {
      label: "Has Active Subscription",
      value: user?.has_active_subscription ? "yes" : "no",
    },
    { label: "Requested Plan", value: user?.plan_subscriptions?.name },
    { label: "Current Routine", value: user?.current_routine },
    {
      label: "Starts At",
      value: user?.plan_subscriptions?.starts_at
        ? formatDate(user?.plan_subscriptions?.starts_at)
        : "N/A",
    },
    {
      label: "Ends At",
      value: user?.plan_subscriptions?.ends_at
        ? formatDate(user?.plan_subscriptions?.ends_at)
        : "N/A",
    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-8 ml-8 w-full">
      {userDetails.map((detail, index) => (
        <div key={index}>
          <label className="block text-sm font-semibold text-gray-600 mb-1">
            {detail.label}:
          </label>
          <input value={detail.value || "N/A"} disabled className="w-full" />
        </div>
      ))}
    </div>
  );
};

export default UserDetails;
