import React, { useEffect, useState } from "react";
import AppStore from "../../assets/images/App Store.svg";
// import GooglePlay from "../../assets/images/Google Play.png";
import DownloadApk from "../../assets/images/Download-APK.svg";
import levelUp1 from "../../assets/images/levelUp1.png";
import levelUp2 from "../../assets/images/levelUp2.png";
import { useTranslation } from "react-i18next";

const APKLINK = process.env.REACT_APP_API_URL_APK;
const APPLELINK = process.env.REACT_APP_API_URL_APPLE;

const LevelUpSection = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [levelUp1, levelUp2];
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage === 0 ? 1 : 0));
    }, 1800);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <section className="bg-[#151515] text-center text-white mb-32 md:mb-[400px]">
      <div className="md:w-[85%] md:max-w-[1300px] mx-auto flex flex-col justify-between md:flex-row gap-10 mt-20 md:mt-40">
        <div className="flex flex-col items-center w-full md:block text-4xl md:w-[50%] md:text-[60px] z-10 font-semibold px-4">
          <p
            dir={`${i18n.language === "en" ? "ltr" : "rtl"}`}
            className={`md:mb-4 text-center md:text-start leading-normal`}
          >
            {t("levelUpTitle")}
          </p>
          <p
            dir={`${i18n.language === "en" ? "ltr" : "rtl"}`}
            className="mb-6 text-[#FF6400] leading-normal md:text-2xl text-center md:text-start text-xl font-semibold"
          >
            {t("appDescription")}
          </p>
          <div
            className={`hidden ${
              i18n.language === "en" ? "justify-start" : "justify-end"
            } md:flex justify-start gap-x-12 py-4 z-10`}
          >
            <a
              href={APKLINK}
              target="_blank"
              rel="noopener noreferrer"
              className="transform transition-transform duration-200 hover:scale-105 hover:shadow-lg rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <img src={DownloadApk} className="w-60" alt="Download APK" />
            </a>
            <a
              href={APPLELINK}
              target="_blank"
              rel="noopener noreferrer"
              className="transform transition-transform duration-200 hover:scale-105 hover:shadow-lg rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <img src={AppStore} className="w-60" alt="App Store" />
            </a>
          </div>
        </div>

        <div className="relative mx-auto z-10 md:right-40 lg:right-36 xl:right-28 2xl:right-28 w-[calc(90%-2rem)] md:w-[500px] pb-[100%] md:pb-0">
          {images.map((image, index) => (
            <img
              key={index}
              loading="lazy"
              src={image}
              alt={`levelUp${index + 1}`}
              className={`absolute md:left-1/4 transition-opacity duration-300 ${
                currentImage === index ? "opacity-100" : "opacity-0"
              }`}
            />
          ))}
        </div>
        <div className="flex flex-col md:hidden mx-auto gap-y-8">
          <div>
            <a
              href={APKLINK}
              target="_blank"
              rel="noopener noreferrer"
              className="focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <img
                src={DownloadApk}
                className="mx-auto transform transition-transform duration-200 hover:scale-105 hover:shadow-lg rounded-lg"
                alt="Google Play"
              />
            </a>
          </div>
          <div>
            <a
              href={APPLELINK}
              target="_blank"
              rel="noopener noreferrer"
              className="focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <img
                src={AppStore}
                className="mx-auto transform transition-transform duration-200 hover:scale-105 hover:shadow-lg rounded-lg"
                alt="App Store"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LevelUpSection;
